export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static gaTrackingId: string;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Launch',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };

    static readonly grid = {
        defaultPageSize: 10,
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '11.0.0';

    static readonly metric = {
        Units: 'units',
        Dollars: 'dollars'
    };

    static readonly studentContactSettingName: string = 'App.TenantManagement.EnableStudentContacts';
    static readonly studentContactPermissionName: string = 'Pages.StudentContacts';
    static readonly studentContactStatPermissionName: string = 'Pages.StudentContacts.Stats';
    static readonly studentContactMinimumNotificationAmount: number = 20;
    static readonly studentContactMinimumAmount: number = 25;
    static readonly studentContactFirstMessage: string = 'StudentContactFirstMessage';
    static readonly studentContactLastMessage: string = 'StudentContactLastMessage';

    static readonly studentStretchIncrementDollars: number = 250;
    static readonly studentStretchIncrementUnits: number = 5;

    static readonly teamStretchIncrementDollars: number = 1000;
    static readonly teamStretchIncrementUnits: number = 50;

    static readonly launchDomain: string = 'launchfundraising.com';
    static readonly smsDomain: string = 'launchraise.com';

    static readonly FUNDRAISER_STUDENT_CONTACT_SLUG_PREFIX: string = 'X';

    static readonly launchbackLinkPermissionName: string = 'Pages.LaunchbackLink';

    // payment types
    static readonly creditcard = 'creditcard';
    static readonly check = 'check';
    static readonly cash = 'cash';
    static readonly applepay = 'applepay';

    // Apple Pay
    static readonly applePaySettingName: string = 'App.TenantManagement.EnableApplePay';


    static elmahApiKey: string;
    static elmahLogId: string;
    static elmahApplication: string;

    // Sideline
    static readonly sidelineSettingName: string = 'App.TenantManagement.IsSidelineEnabled';
    static readonly sidelineReportPermissionsName: string = 'Pages.SidelineReport';
    static readonly sidelineReportPermissionsRead: string = 'Pages.SidelineReport.Read';

    // Permissions
    static readonly editCreditCardOrderPermission = 'Pages.OrderDetails.EditCreditCardOrder';
    static readonly editOrderPermission = 'Pages.OrderDetails.Edit';
}
