<div class="row top-row">
    <div class="col-lg-9">
        <div class="logo-fundraiser-name row" *ngIf="fundraiser">
            <div class="hour-a-thon-org-logo-container col-3 my-auto">
                <img src="{{fundraiser.logoUrl}}" alt="logo" class="hour-a-thon-logo">
            </div>
            <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
            <div class="col-9 hour-a-thon-fundraiser-name yellow-text">
                <div class="d-flex flex-column">
                    <div class="hour-a-thon-organization-name brush-stroke">{{fundraiser.organizationName}}</div>
                    <div class="fundraiser-name-and-powered-by-container">
                        <span class="fundraiser-name">{{fundraiser.fundraiserName}}</span>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 d-flex align-items-center">
        <div class="powered-by-container d-none d-lg-block" *ngIf="fundraiser">
            <img alt="Fundraiser Group Logo" src="{{fundraiser.tenantLogoUrl}}" class="powered-by-logo" />
        </div>
    </div>
</div>
<div #top class="bg-white">    
    <div class="mb-40 overlay d-flex justify-content-center">
        <div class="row middle-row reorder-mobile">
            <div class="col-12">
                <div #quickJump class="d-block d-md-none mb-5">
                    <div class="row text-center">
                        <div class="col-12">
                            <h1>{{l('SideLine.QuickJump')}}:</h1>
                        </div>
                        <div class="col-12 d-flex" [ngClass]="teamResultsChart?.data ? 'justify-content-between' : 'justify-content-center'">
                            <div class="mb-1"><a class="btn btn-outline text-primary text-decoration-underline fs-4" (click)="scrollToElement(participantResults)">{{l('SideLine.ParticipantResults')}}</a></div>
                            <div class="mb-1" *ngIf="teamResultsChart?.data"><a class="btn btn-outline text-primary text-decoration-underline fs-4" (click)="scrollToElement(teamResults)">{{l('SideLine.TeamResults')}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <h1 #fundraiserOverview class="text-center fs-3x mb-3 mb-md-7">{{l('SideLine.FundraiserOverview')}}</h1>
            <div class="col-lg-7 col-sm-12 tiles order-2 order-md-1">
                <div class="row">
                    <div class="col-12 col-sm-4 d-flex justify-content-center">
                        <div class="tile contacts" *ngIf="totalContacts !== null && totalContacts !== undefined && totalContacts >= 0">
                            <div class="d-flex align-items-center justify-content-center mt-2 mb-1">
                                <span class="fw-bolder">{{l('SideLine.TotalContacts')}} <i class="fa fa-lg fa-user-circle text-dark ms-1"></i></span>
                            </div>
                            <div class="fw-boldest fs-4hx">{{totalContacts}}</div>
                            <div class="mt-1 mb-2"><span class="fw-bolder">{{contactGoalPercentage}}%</span> of {{contactGoal}}</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 d-flex justify-content-end justify-content-sm-center">
                        <div class="tile texts" *ngIf="totalCallsAndTexts !== null && totalCallsAndTexts !== undefined && totalCallsAndTexts.totalTexts >= 0">
                            <div class="d-flex align-items-center justify-content-center mt-2 mb-1">
                                <span class="fw-bolder">{{l('SideLine.TotalTexts')}} <i class="fa fa-lg fa-comment text-dark ms-1"></i></span>
                            </div>
                            <div class="fw-boldest fs-4hx">{{totalCallsAndTexts.totalTexts}}</div>
                            <div class="mt-1 mb-2"><span class="fw-bolder">{{textsGoalPercentage}}%</span> of {{contactGoal}}</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 d-flex justify-content-sm-center justify-content-start">
                        <div class="tile calls" *ngIf="totalCallsAndTexts !== null && totalCallsAndTexts !== undefined && totalCallsAndTexts.totalCalls >= 0">
                            <div class="d-flex align-items-center justify-content-center mt-2 mb-1">
                                <span class="fw-bolder">{{l('SideLine.TotalCalls')}} <i class="fa fa-lg fa-phone-alt text-dark ms-1"></i></span>
                            </div>
                            <div class="fw-boldest fs-4hx">{{totalCallsAndTexts.totalCalls}}</div>
                            <div class="mt-1 mb-2"><span class="fw-bolder">{{callsGoalPercentage}}%</span> of {{contactGoal}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12 d-flex justify-content-center order-1 order-md-2">
                <div class="tile fundraiserProgress" *ngIf="fundraiserProgress">
                    <div class="fw-bolder mt-2 mb-1">{{l('SideLine.FundraiserProgress')}}</div>
                    <div class="fw-boldest fs-4hx">{{fundraiserProgress.totalDonations + fundraiserProgress.totalPaid | currency:'USD':'symbol':'1.0-0'}} {{l('SideLine.Raised')}}</div>
                    <div class="mt-1 mb-2"><span class="fw-bolder">{{progressGoalPercentage}}%</span> {{l('SideLine.OfGoal')}} {{fundraiserProgress.teamGoal | currency:'USD':'symbol':'1.0-0'}}</div>
                </div>
            </div>
            <div class="row mt-5 order-3">
                <div class="{{ teamResultsChart?.data ? 'col-lg-8' : 'col-lg-12'}}">
                    <h1 #participantResults class="text-center fs-3x mb-3 mb-md-7">{{l('SideLine.ParticipantResults')}}</h1>
                    <div class="d-flex flex-column flex-lg-row gap-3 gap-lg-10">
                        <div class="flex-grow-1" style="flex-basis: 60%;">
                            <div class="input-group search-input-group">
                                <span class="input-group-text bg-white border-end-0 rounded-pill-start">
                                    <i class="bi bi-search"></i>
                                </span>
                                <input class="form-control border-start-0 rounded-pill-end"
                                       type="text"
                                       [(ngModel)]="filterText"
                                       (input)="onSearch()"
                                       placeholder="{{ 'SearchWithThreeDot' | localize }}">
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-lg-auto" style="flex-basis: 40%;" *ngIf="participantTeams.length > 0">
                            <p-multiSelect [options]="participantTeams"
                                           [(ngModel)]="participantTeams.teamName" name="participantTeams.teamName"
                                           (onChange)="onSelectedTeamChange($event)" [displaySelectedLabel]="true"
                                           optionLabel="teamName" optionValue="teamId"
                                           [defaultLabel]="l('SelectSidelineTeams')" styleClass="w-100">
                            </p-multiSelect>
                        </div>
                    </div>
                    <p-table #dataTable *ngIf="participants"
                             id="participantResults"
                             [value]="participants"
                             [paginator]="false"
                             [lazy]="false"
                             [scrollable]="true"
                             ScrollWidth="100%">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="name">{{l('SideLine.Name')}}</th>
                                <th class="d-flex justify-content-center">{{l('SideLine.ParentLink')}}</th>
                                <th><i class="fa fa-lg fa-user-circle text-dark"></i></th>
                                <th><i class="fa fa-lg fa-comment text-dark"></i></th>
                                <th><i class="fa fa-lg fa-phone-alt text-dark"></i></th>
                                <th>{{l('SideLine.Raised')}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit" let-index="rowIndex">
                            <tr>
                                <td class="name">{{record.name}}</td>
                                <td class="d-flex justify-content-sm-center"><i class="fa fa-check" *ngIf="record.hasParentLink"></i></td>
                                <td>{{record.contacts}}</td>
                                <td>{{record.texts}}</td>
                                <td>{{record.calls}}</td>
                                <td>{{record.raised | currency:'USD':'symbol':'1.0-0'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="col-lg-4" [hidden]="!teamResultsChart?.data">
                    <h1 #teamResults [hidden]="!teamResultsChart?.data" class="text-center mt-7 mt-md-0 fs-3x mb-3 mb-md-7">{{l('SideLine.TeamResults')}}</h1>
                    <canvas [hidden]="!teamResultsChart?.data" class="chart" id="teamResultsChart">{{teamResultsChart}}</canvas>
                </div>
            </div>
        </div>
        <button *ngIf="pageYoffset > 700"
                class="scroll-to-top-btn px-3"
                (click)="scrollToElement(top)">
            {{l('BackToTop')}}
        </button>
    </div>
    <footer class="sideline-footer">
        <div class="col-12 justify-content-center d-flex flex-row">
            <div class="justify-content-center" *ngIf="fundraiser">
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="footer-organization-logo-container my-auto">
                        <img alt="Fundraiser Group Logo" src="{{fundraiser.tenantLogoUrl}}" class="powered-by-logo" />
                    </div>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a target="_blank" href="https://www.launchfundraising.com/get-started">Contact Us</a>
                    <a target="_blank" href="https://www.launchfundraising.com/get-started">Run your own Fundraiser</a>
                    <a target="_blank" href="https://www.launchfundraising.com/get-started">Join our team</a>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a href="{{appUrlService.appRootUrl}}TermsOfUse.html" target="_blank">Terms of Use</a>
                    <a href="https://www.launchfundraising.com/privacy-policy" target="_blank">Privacy Policy</a>
                </div>
                <div class="powered-by-row mb-0 d-flex flex-row justify-content-center w-100">
                    <div class="yellow-text d-flex align-items-end">
                        <div class="d-flex flex-column">
                            <div class="fundraiser-name-and-powered-by-container">
                                <div class="powered-by-container">
                                    <div class="powered-by-text text-white">{{l('PoweredBy')}}</div>
                                    <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
