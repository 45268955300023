import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FundraiserDirectoryComponent } from '@app/main/launch/fundraisers/fundraiser-directory/fundraiser-directory.component';
import { SignComponent } from './app/main/launch/sign/sign.component';
import { LiveLinkComponent } from './app/main/launch/fundraisers/live-link/live-link.component';
import { FundraiserHomeComponent } from './app/main/customer/fundraiser-home/fundraiser-home.component';
import { LaunchathonFundraiserHomeComponent } from './app/main/customer/launchathon-fundraiser-home/launchathon-fundraiser-home.component';
import { CalendarFundraiserHomeComponent } from './app/main/customer/calendar-fundraiser-home/calendar-fundraiser-home.component';
import { ProductFundraiserHomeComponent } from './app/main/customer/product-fundraiser-home/product-fundraiser-home.component';
import { ParentStudentContactsComponent } from '@app/main/customer/parent-student-contacts/parent-student-contacts.component';
import { SidelineReportComponent } from '@app/main/launch/fundraisers/sideline/sideline-report.component';
import { AppConsts } from './shared/AppConsts';

const routes: Routes = [
    { path: '', redirectTo: '/app/main/fundraiser', pathMatch: 'full' },
    { path: 'sign/:agreementGuid', component: SignComponent},
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), //Lazy load account module
        data: { preload: true },
    },
    { path: 'join', redirectTo: '/account/join' },
    { path: 'order/:fundraiserId/:fundraiserUserId', component: FundraiserHomeComponent, data: {} },
    { path: 'orderinfo/:fundraiserId/:fundraiserUserId', component: FundraiserHomeComponent, data: {} },
    { path: 'OrderInfo/:fundraiserId/:fundraiserUserId', component: FundraiserHomeComponent, data: {} },

    // need to keep these routes, I guess, for any email remarketing links generated before ~3/11/2024.
    { path: 'order/:slug', component: FundraiserHomeComponent, data: {} },
    { path: 'orderinfo/:slug', component: FundraiserHomeComponent, data: {} },
    // this was the route to go through the OrderInfo MVC page, but that's only relevant for share links, not for email remarketing links.
    // and for email remarketing links, we can just go straigt to the component, since there's no need for the MVC page's scraper content.
    { path: 'orderInfo/:slug', component: FundraiserHomeComponent, data: {} },

    // put this here instead of in main-routing so it's full view (no left nav or top menu)
    { path: 'fundraiserHome/:fundraiserUserId/viewSample/:slug', component: FundraiserHomeComponent, data: { permission: 'Pages.Fundraisers.Edit' } },

    // this is being used in third party integrations (i.e. Salesforce), so be careful when removing or updating this.
    { path: 'fundraiserHome/:fundraiserUserId/:slug', component: FundraiserHomeComponent, data: {} },
    { path: 'fundraiserHome/:fundraiserUserId/:slug/:automatedMessageType', component: FundraiserHomeComponent, data: {} },
    
    { path: 'fundraiserHomeInfo/:fundraiserUserId/:slug', component: FundraiserHomeComponent, data: {} },
    { path: 'fundraiserHomeInfo/:fundraiserUserId/:slug/:automatedMessageType', component: FundraiserHomeComponent, data: {} },

    { path: 'find/:groupName', component: FundraiserDirectoryComponent, data: { }},
    { path: 'find', redirectTo: 'find/', pathMatch: 'full' },

    { path: 'livelink/:slug', component: LiveLinkComponent, data: {} },
    
    // put this here instead of in main-routing so it's full view (no left nav or top menu)
    { path: 'fundraisers/sideline/:slug', component: SidelineReportComponent, data: { permission: [AppConsts.sidelineReportPermissionsRead] } },

    { path: 'launchathonFundraiserHome/student/:fundraiserUserId', component: LaunchathonFundraiserHomeComponent, data: {} },
    { path: 'launchathonfundraiserhome/student/:fundraiserUserId', component: LaunchathonFundraiserHomeComponent, data: {} },

    // put this here instead of in main-routing so it's full view (no left nav or top menu)
    { path: 'launchathonFundraiserHome/fundraiser/viewSample/:slug', component: LaunchathonFundraiserHomeComponent, data: { permission: 'Pages.Fundraisers.Edit' } },
    { path: 'launchathonFundraiserHome/fundraiser/:slug', component: LaunchathonFundraiserHomeComponent, data: {} },
    { path: 'launchathonfundraiserhome/fundraiser/:slug', component: LaunchathonFundraiserHomeComponent, data: {} },

    { path: 'calendarFundraiserHome/student/:fundraiserUserId', component: CalendarFundraiserHomeComponent, data: {} },
    { path: 'calendarfundraiserhome/student/:fundraiserUserId', component: CalendarFundraiserHomeComponent, data: {} },

    // put this here instead of in main-routing so it's full view (no left nav or top menu)
    { path: 'calendarFundraiserHome/fundraiser/viewSample/:slug', component: CalendarFundraiserHomeComponent, data: { permission: 'Pages.Fundraisers.Edit' } },
    { path: 'calendarFundraiserHome/fundraiser/:slug', component: CalendarFundraiserHomeComponent, data: {} },
    { path: 'calendarfundraiserhome/fundraiser/:slug', component: CalendarFundraiserHomeComponent, data: {} },

    { path: 'productFundraiserHome/student/:fundraiserUserId', component: ProductFundraiserHomeComponent, data: {} },
    { path: 'productfundraiserhome/student/:fundraiserUserId', component: ProductFundraiserHomeComponent, data: {} },

    // put this here instead of in main-routing so it's full view (no left nav or top menu)
    { path: 'productFundraiserHome/fundraiser/viewSample/:slug', component: ProductFundraiserHomeComponent, data: { permission: 'Pages.Fundraisers.Edit' } },
    { path: 'productFundraiserHome/fundraiser/:slug', component: ProductFundraiserHomeComponent, data: {} },
    { path: 'productfundraiserhome/fundraiser/:slug', component: ProductFundraiserHomeComponent, data: {} },

    //student contacts - link for parents to add contacts
    { path: 'studentContacts', component: ParentStudentContactsComponent, data: { title: 'Student Contacts' } },

    {
        path: '**', component: ErrorPageComponent, 
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {
    constructor(private router: Router, private _uiCustomizationService: AppUiCustomizationService) {
        router.events.subscribe((event: NavigationEnd) => {
            setTimeout(() => {
                this.toggleBodyCssClass(event.url);
            }, 0);
        });
    }

    toggleBodyCssClass(url: string): void {
        if (url) {
            if (url === '/') {
                if (abp.session.userId > 0) {
                    this.setAppModuleBodyClassInternal();
                } else {
                    this.setAccountModuleBodyClassInternal();
                }
            }

            if (url.indexOf('/account/') >= 0) {
                this.setAccountModuleBodyClassInternal();
            } else {
                this.setAppModuleBodyClassInternal();
            }
        }
    }

    setAppModuleBodyClassInternal(): void {
        let currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('brand-minimize') >= 0) {
            classesToRemember += ' brand-minimize ';
        }

        if (currentBodyClass.indexOf('aside-left-minimize') >= 0) {
            classesToRemember += ' aside-left-minimize';
        }

        if (currentBodyClass.indexOf('brand-hide') >= 0) {
            classesToRemember += ' brand-hide';
        }

        if (currentBodyClass.indexOf('aside-left-hide') >= 0) {
            classesToRemember += ' aside-left-hide';
        }

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAppModuleBodyClass() + ' ' + classesToRemember;
    }

    setAccountModuleBodyClassInternal(): void {
        let currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAccountModuleBodyClass() + ' ' + classesToRemember;
    }

    getSetting(key: string): string {
        return abp.setting.get(key);
    }
}
