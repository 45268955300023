<!-- TODO: Add different components for each payment method once applicable -->
<div class="row inputs-column mb-2 mt-3">
    <ng-container *ngIf="isGuest && isNewOrder()">
        <div>
            <div class="d-flex row" *ngIf="guestPaymentMethods?.length > 1">
                <h2>{{l('SelectAPaymentMethod')}}</h2>
                <div class="col-12" *ngFor="let paymentMethod of guestPaymentMethods">
                    <div class="d-flex flex-row align-items-center">
                        <p-radioButton 
                            [inputId]="paymentMethod.programValue"
                            name="paymentMethod.itemText" 
                            [value]="paymentMethod.id" 
                            [(ngModel)]="orderDetail.paymentMethodId"
                            (onClick)="changePaymentMethod(paymentMethod.id)"
                            [label]="(paymentMethod.programValue === applepay ? 'New! ' : '') + paymentMethod.displayName"
                            styleClass="my-3 border-1 border-y d-flex justify-content-start">
                        </p-radioButton>
                        <img src="/assets/common/images/apple_pay_icon.svg" alt="Apple Pay Icon" class="ps-5 mh-25px" *ngIf="paymentMethod.programValue === applepay">
                    </div>
                </div>
            </div>
        </div>
        <!-- Credit Card -->
        <ng-container *ngIf="isPaymentMethod(creditcard)">
            <div class="col-7">
                <div class="h-20px">{{ orderDetail.creditCardInfo.creditCardNumber ? l('DebitOrCreditCard') : ''}}</div>
                <input #creditCardNumberInput="ngModel" name="{{l('DebitOrCreditCard')}}"
                        type="text" [(ngModel)]="orderDetail.creditCardInfo.creditCardNumber" placeholder="{{l('DebitOrCreditCard')}}"
                        required minlength="13" maxlength="20" data-lpignore="true" />
                <validation-messages [formCtrl]="creditCardNumberInput"></validation-messages>
            </div>
            <div class="col-5 pl-2 d-flex mt-9">
                <div class="credit-card-icons nowrap d-flex">
                    <i class="fab fa-cc-visa fa-xs text-darker"></i>
                    <i class="fab fa-cc-mastercard fa-xs text-darker"></i>
                    <i class="fab fa-cc-discover fa-xs text-darker"></i>
                    <i class="fab fa-cc-amex fa-xs text-darker"></i>
                </div>
            </div>

            <div class="col-6 col-md-3">
                <div class="h-20px">{{expires ? l("ExpDate") : ''}}</div>
                <p-inputMask #expiresInput="ngModel" name="{{l('ExpDate')}}" [autoClear]="false"
                                mask="99/99" [(ngModel)]="expires" placeholder="{{l('ExpDate')}}" ngDefaultControl
                                (onInput)="validateExpiresDate()" (onBlur)="validateExpiresDate()"
                                class="form-control contact-field"
                                required data-lpignore="true">
                </p-inputMask>
                <span *ngIf="expiresErrorMessage" class="validation-error">{{expiresErrorMessage}}</span>
            </div>

            <div class="col-6 col-md-2">
                <div class="h-20px d-inline-block">
                        {{ orderDetail.creditCardInfo.cvv ? l("CVVShort") : ''}}&nbsp;
                    <a href="https://www.cvvnumber.com/cvv.html" target="_blank"
                        class="order-cart-help-circle" rel="noopener noreferrer" title="{{l('CVVShort')}}">
                        <i class="fa fa-question-circle"></i>
                    </a>
                </div>
                <input #cvvInput="ngModel" name="{{l('CVVShort')}}"
                        type="text" [(ngModel)]="orderDetail.creditCardInfo.cvv" placeholder="{{l('CVVShort')}}"
                        required minlength="3" maxlength="4" pattern="^[0-9]+$" data-lpignore="true" />
                <span *ngIf="!cvvInput.valid && cvvInput.touched" class="validation-error">{{l("CVVInvalid")}}</span>
            </div>
        </ng-container>

        <!-- Apple Pay -->
        <div class="row mt-3 w-75 mx-auto ng-star-inserted d-flex justify-content-center" *ngIf="isPaymentMethod(applepay)">
            <p class="text-center">{{l('UsingApplePayPaymentMethod')}}</p>
        </div>

        <div class="col-4" *ngIf="isLaunchathon">
            <div class="h-20px">{{l("Total")}}</div>
            <div class="total">
                {{orderTotalsUpdateResult?.orderTotal + tipDollars | currency:'USD':'symbol':'1.2-2'}}
            </div>
        </div>
    </ng-container>


    <div *ngIf="!isGuest || !isNewOrder()" class="mt-5">
        <!-- in-person sales can select payment method -->
        <ng-container *ngIf="!(isPaymentMethod(creditcard) && orderDetail.invoice)">
            <div class="mb-3 d-flex flex-center unset-height">
                <div class="row unset-height">
                    <button class="btn payment-button border-1 border-dark-launch"
                            [ngClass]="(orderDetail.paymentMethodId == paymentMethod.id)?'btn-selected':'btn-outline'"
                            type="button" *ngFor="let paymentMethod of paymentMethods"
                            [hidden]="!isNewOrder() && paymentMethod.abbreviation == 'cc'"
                            (click)="orderDetail.paymentMethodId = paymentMethod.id">
                        {{paymentMethod.displayName}}
                    </button>
                    <div *ngIf="isPaymentMethod(creditcard)">
                        <span><strong>{{creditCardText}}</strong></span>
                    </div>
                </div>
            </div>

            <div class="col-12 unset-height" *ngIf="isPaymentMethod(check)">
                <div class="mb-5 unset-height">
                    <strong>
                        {{l("ChecksPayableName")}}: <span class="bg-yellow">{{checksPayableName}}</span>
                    </strong>
                </div>
                <div class="mb-5 unset-height">
                    <label>{{l("CheckNumber")}}</label>
                    <input type="text" class="form-control" [(ngModel)]="orderDetail.checkNumber" title="{{l('CheckNumber')}}"
                            (onInput)="validateCheckNumber()" (onBlur)="validateCheckNumber()"
                            name="{{l('CheckNumber')}}" minlength="0" maxlength="10" data-lpignore="true" />
                </div>
                <span *ngIf="checkNumberValidationMessage" class="validation-error">{{checkNumberValidationMessage}}</span>
            </div>

            <div class="col-12 unset-height" *ngIf="isPaymentMethod(creditcard) && !creditCardText" [hidden]="!isNewOrder()">
                <div class="mb-5 unset-height">
                    <div class="h-20px">{{customerCCOrderEmailAddress ? l("CustomerEmailAddress") : ''}}</div>
                    <input #addressEmail="ngModel" type="text" class="form-control form-control-format"
                            [(ngModel)]="customerCCOrderEmailAddress" name="{{l('CustomerEmailAddress')}}" placeholder="{{l('CustomerEmailAddress')}}"
                            [pattern]="emailAddressPattern" minlength="0"
                            maxlength="128" required data-lpignore="true" />
                    <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]" [formCtrl]="addressEmail"></validation-messages>
                </div>
                <div class="mb-5 unset-height">
                    <button class="btn btn-primary payment-button" type="button" (click)="sendSharedOrder()"
                            [disabled]="!customerCCOrderEmailAddress || addressEmail.invalid">
                        {{l("SendOrder")}}
                    </button>
                </div>
                <div class="alert alert-warning unset-height">
                    {{l("YouMustBeLoggedOut")}}
                </div>
            </div>

            <ng-container *ngIf="!studentCreditCardOnly">
                <div class="mb-3 d-flex flex-center unset-height">
                    <div class="row student-buttons-row">
                        <button class="btn payment-button border-1 border-dark-launch"
                                [ngClass]="(orderDetail.paid && paidIsSet)?'btn-selected':'btn-outline'"
                                type="button" (click)="orderDetail.paid = true; paidIsSet = true;">
                            {{l("Paid")}}
                        </button>
                        <button class="btn payment-button border-1 border-dark-launch"
                                [ngClass]="(!orderDetail.paid && paidIsSet)?'btn-selected':'btn-outline'"
                                type="button" (click)="orderDetail.paid = false; paidIsSet = true;">
                            {{l("NotPaid")}}
                        </button>
                    </div>
                </div>
            </ng-container>

            <div class="mb-3 d-flex flex-center unset-height" *ngIf="!studentCreditCardOnly && isAProductSelected">
                <div class="row student-buttons-row">
                    <button class="btn payment-button border-1 border-dark-launch"
                            [ngClass]="(orderDetail.delivered)?'btn-selected':'btn-outline'" type="button"
                            (click)="orderDetail.delivered = true">
                        {{l("Delivered")}}
                    </button>
                    <button class="btn payment-button border-1 border-dark-launch"
                            [ngClass]="(orderDetail.delivered !== undefined && !orderDetail.delivered)?'btn-selected':'btn-outline'" type="button"
                            (click)="orderDetail.delivered = false">
                        {{l("NotDelivered")}}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!isNewOrder() && isPaymentMethod(creditcard) && orderDetail.invoice">
        <div class="row">
            <div class="col">
                {{l("CreditCardSales")}} {{l("Invoice")}}: {{orderDetail.invoice}}
            </div>
        </div>
    </div>
    <div class="col-12 d-flex align-items-center my-3">
        <button type="button" class="customer-submit-button customer-button primary-bg" (click)="goToReviewOrder()">{{l('ReviewOrder')}}</button>
    </div>
</div>