import { Component, Injector, Input, HostBinding, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { GetFundraiserForLiveLink, HourathonOrder } from '../../../../../../shared/service-proxies/service-proxies';
import {
    trigger,
    state,
    style,
    animate,
    transition
    // ...
} from '@angular/animations';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import * as confetti from 'canvas-confetti';

enum AudioLowerBoundThresholds {
    KaChing = 10,
    Encore = 250,
    Yeah = 500,
}

enum AnimationLowerBoundThresholds {
    Fireworks = 500,
}

@Component({
    selector: 'live-link-activity',
    templateUrl: './live-link-activity.component.html',
    styleUrls: ['./live-link-activity.component.less'],
    animations: [
        trigger('shiftDown', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(65px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),

        trigger('fadeIn', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate('2s 0s ease', style({ opacity: 1}))
            ])
        ]),
    ]
})
export class LiveLinkActivityComponent extends AppComponentBase implements OnDestroy {
    @ViewChild('ordersList', { static: true }) ordersListElement: ElementRef;

    newestHourathonOrder: HourathonOrder = new HourathonOrder();
    orders: HourathonOrder[];
    isFirstLoad: boolean = true;
    kaching: HTMLAudioElement;
    encoreAudio: HTMLAudioElement;
    yeahAudio: HTMLAudioElement;
    _fundraiserForLiveLink: GetFundraiserForLiveLink;

    showEnableSoundButton: boolean = false;

    // _fireworks: FireworksInstance | undefined;
    _particlesDurationInMs: number = 3400;
    _previousTotalRaisedThreshold: number = -1;
    _animationLowerBoundThreshold: number = 1000;

    _confetti: confetti.CreateTypes;

    @Input() set fundraiserForLiveLink(value: GetFundraiserForLiveLink) {
        if (!value || (
            // this comes from a signal-r message meant for updating something other than totals.
            !value.latestOrders && !value.totalRaised
        )) {
            return;
        }

        this._fundraiserForLiveLink = value;        
        this.orders = this._fundraiserForLiveLink.latestOrders.slice(1, 10);

        if (this._previousTotalRaisedThreshold === -1) {
            this._previousTotalRaisedThreshold = this.calcualteCurrentThreshold();
        }

        // don't trigger animation on page load:
        if (!this.isFirstLoad) {
            this.newestHourathonOrder = undefined;

            this.playAudio();
            this.playAnimations();

            setTimeout(() => {
                this.isShiftDown = true;
                //this.newestHourathonOrder = this._fundraiserForLiveLink.latestOrders[0];
            }, 1)
            setTimeout(() => {
                // update the lists now that the animation has finished:
                this.orders = this._fundraiserForLiveLink.latestOrders.slice(1, 10);
                this.isShiftDown = false;
                this.newestHourathonOrder = this._fundraiserForLiveLink.latestOrders[0];
            }, 2000)
        }
        else {
            this.isFirstLoad = false;
            this.newestHourathonOrder = this._fundraiserForLiveLink.latestOrders[0];
        }
    }

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._fundraiserForLiveLink = new GetFundraiserForLiveLink();

        this._confetti = confetti.create(
            document.querySelector('activity-container'),
            { resize: true }
        );

        this.kaching = new Audio();
        this.kaching.src = '/assets/common/sounds/ka-ching-final.mp3';
        this.kaching.load();

        this.encoreAudio = new Audio();
        this.encoreAudio.src = '/assets/common/sounds/Encore.mp3';
        this.encoreAudio.load();

        this.yeahAudio = new Audio();
        this.yeahAudio.src = '/assets/common/sounds/Yeah!.mp3';
        this.yeahAudio.load();
    }
    
    isShiftDown: boolean = undefined;

    executeActivityAudio = (promise: Promise<void>, isRetry: boolean = false) => {
        if (promise !== undefined) {
            promise.then(_ => {}).catch(_ => {
                // playing sound was blocked by the browser, which requires a user 'interaction'.
                // let's prompt for an interaction, while also giving the user a chance to say they don't want sounds.
                this.message.confirm(this.l('HowToMute'), this.l('EnableSound'), (isConfirmed) => {
                    if (isConfirmed && !isRetry) {
                        // the user clicking a button is enough of an interaction to trigger the browser to allow playing sounds now.
                        // play the sound to 'confirm':
                        this.executeActivityAudio(promise, true);
                    } else {
                        this.playSounds = false;
                    }
                });
            });
        }
    }

    playSounds: boolean = true;
    playAudio() {
        if (!this.playSounds) {
            return;
        }

        if (this.isTotalRaisedThresholdSurpassed() || this._fundraiserForLiveLink?.latestOrders[0]?.orderAmount >= AudioLowerBoundThresholds.Yeah) {
            this.executeActivityAudio(this.yeahAudio.play());
        } else if (this._fundraiserForLiveLink?.latestOrders[0]?.orderAmount >= AudioLowerBoundThresholds.Yeah) {
            this.executeActivityAudio(this.yeahAudio.play());
        } else if (this._fundraiserForLiveLink?.latestOrders[0]?.orderAmount >= AudioLowerBoundThresholds.Encore) {
            this.executeActivityAudio(this.encoreAudio.play());
        } else if (this._fundraiserForLiveLink?.latestOrders[0]?.orderAmount >= AudioLowerBoundThresholds.KaChing) {
            this.executeActivityAudio(this.kaching.play());
        } else {
            return;
        }
    }

    playAnimations() {
        if (this.isTotalRaisedThresholdSurpassed()) {
            this.executeConfetti();
        }

        // Update value AFTER threshold check
        this._previousTotalRaisedThreshold = this.calcualteCurrentThreshold();
        
        if (this._fundraiserForLiveLink?.latestOrders[0]?.orderAmount >= AnimationLowerBoundThresholds.Fireworks) {
            this.executeFireworks();
        }
    }

    executeConfetti() {
        this._confetti({
            particleCount: 50,
            angle: 90,
            spread: 45,
            startVelocity: 45,
            decay: 0.9,
        });
    }

    executeFireworks() {
        const animationEnd = Date.now() + this._particlesDurationInMs;
        const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 9999 };

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / this._particlesDurationInMs);
            
            this._confetti({ ...defaults, particleCount, origin: { x: Math.random(), y: Math.random() - 0.2 } });
            this._confetti({ ...defaults, particleCount, origin: { x: Math.random(), y: Math.random() - 0.2 } });
        }, 250);
    }

    calcualteCurrentThreshold(): number {
        return Math.floor(this._fundraiserForLiveLink?.totalRaised / this._animationLowerBoundThreshold);
    }

    isTotalRaisedThresholdSurpassed(): boolean {
        return (this.calcualteCurrentThreshold() - this._previousTotalRaisedThreshold) >= 1;
    }

    ////  --- for testing ---
    //iteration: number = 0;
    //shift() {
    //    this.newestHourathonOrder = undefined;

    //    setTimeout(() => {
    //        // change the orders slice to include the previously-newest order
    //        this.orders = this._fundraiserForLiveLink.latestOrders.slice(9 - this.iteration, 19 - this.iteration);
    //        this.iteration++;
    //    }, 1)

    //    setTimeout(() => {
    //        this.isShiftDown = true;
    //    }, 1)

    //    setTimeout(() => {
    //        // update the lists after the animation has finished.
    //        //this.newestHourathonOrder = this._fundraiserForLiveLink.latestOrders[6 - this.iteration];
    //        this.orders = this._fundraiserForLiveLink.latestOrders.slice(10 - this.iteration, 19 - this.iteration);
    //        this.isShiftDown = undefined;

    //        this.newestHourathonOrder = this._fundraiserForLiveLink.latestOrders[9 - this.iteration];
    //    }, 2000)
    //}

}